<template>
  <div>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <hr>
    <h2>Journal Categories</h2>
    <div class="white-bg-container">
      <b-row>
        <b-col
          md="2"
          style="padding-top: 0.5rem"
        >
          <span>Add Category:</span>
        </b-col>
        <b-col>
          <b-form-input
            id="addCategory"
            v-model="Name"
            placeholder=""
            name="addCategory"
          />
        </b-col>
        <b-col>
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            size="md"
            @click="saveCategory()"
          >
            Save Category
          </b-button>
        </b-col>
      </b-row>
      <b-form-group
        label="Mark Categories for Deletion:"
        label-for="categoriesForDeletion"
        class="mt-2"
      >
        <b-row id="categoriesForDeletion">
          <b-col class="pl-2">
            <b-row
              v-for="item in journalEntryCategory"
              :key="item.id"
            >
              <b-col>
                <b-form-checkbox
                  :id="'categoryForDeletion' + item.id"
                  :name="'categoryForDeletion' + item.id"
                  @change="markDeletionCategory(item.id)"
                >
                  {{ item.title }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form-group>
      <b-row>
        <b-col>
          <b-button
            type="reset"
            variant="danger"
            class="mr-1"
            size="md"
            @click="deleteCheckedCategories()"
          >
            Delete Checked Categories
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-modal
      id="modal-delete-category"
      ok-only
      ok-variant="danger"
      ok-title="Delete category"
      modal-class="modal-danger"
      centered
      title="Confirm Delete category"
      @ok="confirmDeleteCategory()"
    >
      <div v-if="CategoryIds !== null">
        <h2 class="text-center">
          You are about to delete a Journal Category.
        </h2>
      </div>
      <div class="text-center">
        This action cannot be undone, are you sure?
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";

const apiService = new APIService();

export default {
  components: {
    BBreadcrumb,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      Name: "",
      journalEntryCategory: [],
      CategoryIds: [],
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Admin',
        },
        {
          text: 'Utility',
          href: '/admin/utility'
        },
        {
          text: "Journal Categories",
        },
      ]
    },
  },
  async created() {
    try {
      this.loadCategory();
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    async loadCategory() {
      const response = await apiService.get('journal/categories').then(res => res.data).catch(error => error)
      this.journalEntryCategory = response.categories.map(i => ({title: i.value, id: i.id}));
    },
    saveCategory() {
      const postData = {
        "Name": this.Name
      }
      apiService
          .post("journal/category/create", postData)
          .then(res => {
            if (res) {
              this.showToast('success', 'top-center', 4000, 'Category created!');
              this.loadCategory();
              this.Name = "";
            }
          })
    },
    markDeletionCategory(val) {
      if (this.CategoryIds.indexOf(val) === -1) {
        this.CategoryIds.push(val);
      } else {
        this.CategoryIds = this.CategoryIds.filter(i => i !== val)
      }
    },
    deleteCheckedCategories() {
      this.$bvModal.show('modal-delete-category');
    },
    confirmDeleteCategory() {
      const postData = {
        "CategoryIds": this.CategoryIds,
      }
      apiService
          .post("journal/categories/delete", postData)
          .then(res => {
            if (res) {
              this.showToast('success', 'top-center', 4000, 'Category deleted!');
              this.loadCategory();
              this.CategoryIds = [];
            }
          })
    },
  },
}
</script>

<style scoped>

</style>